<template>
  <div class="login-page">
    <el-dropdown class="custom-dropdown" popper-class="custom-dropdown-popper">
      <span class="el-dropdown-link custom-dropdown-link" style="border: none !important; box-shadow: none !important;">
        <GlobalIcon/>

        <span :style="{ color: 'white', 'margin-right': '10px' }"> {{ $t('settingPage.language') }}</span>
        <el-icon
            style="color: white;font-size: 10px;">
          <ArrowDown/>
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="(language, friendlyName) in supportedLanguages" :key="friendlyName"
                            @click="changeLanguage(friendlyName)" @mouseenter="hover = friendlyName"
                            @mouseleave="hover = null"
                            :style="hover === friendlyName ? 'color: rgb(80,80,80); background-color: rgb(245,245,245);' : 'color: rgb(80,80,80); font-size:14px;background-color: transparent;'">
            {{
              friendlyName
            }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

    <div class="login-container">
      <div class="left-pane">
        <img id="login-left" :src="require('@/assets/image/left_logo.png')" alt="Logo"/>
        <div class="left-text">{{ $t('LoginPage.onlineServicePlatform') }}</div>
      </div>
      <div class="right-pane">
        <div class="main-form" v-if="currentForm === 'login'">
          <h3 class="h3-text">{{ $t('LoginPage.welcomeLogin') }}</h3>
          <el-form ref="loginForm" :model="loginData" :rules="loginFormRules" label-width="50px" label-position="top">
            <el-form-item :label=" $t('LoginPage.username')" prop="username">
              <el-input class="other-input" v-model="loginData.username" :placeholder="$t('LoginPage.enterEmail')"
                        @blur="handleBlur('username')">
                <template #prepend>
                  <el-icon class="larger-icon">
                    <User/>
                  </el-icon>
                </template>
              </el-input>

            </el-form-item>
            <el-form-item :label=" $t('LoginPage.password')" prop="password">

              <el-input class="other-input" v-model="loginData.password" type="password" :show-password="true"
                        :placeholder="$t('LoginPage.enterPassword')">
                <template #prepend>
                  <el-icon class="larger-icon">
                    <Lock/>
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>

            <div class="forgot-password">
              <a href="https://www.ananda-drive.com/innovative-R&D/ananda-link/" target="_blank" style="text-decoration: none"><span
                  class="link-text-style">{{ $t('LoginPage.createAccount') }}</span></a> <span
                class="link-text-style"> | </span>
              <a href="#" @click="showResetPasswordForm" style="text-decoration: none"><span
                  class="link-text-style">{{ $t('LoginPage.forgotPassword') }}</span></a>
            </div>

            <div class="privacy-agreement">
              <el-checkbox v-model="loginData.agree">
                <span style="color: black;">{{ $t('LoginPage.readAndAgree') }}</span>
                <a :href="termsOfServiceLink" target="_blank"
                   class="terms-link" style="text-decoration: none"><span
                    class="link-text-style">{{ $t('LoginPage.termsOfService') }}</span></a>
                <a :href="legalNoticeLink" target="_blank"
                   class="terms-link" style="text-decoration: none"><span
                    class="link-text-style">{{ $t('LoginPage.legalNotice') }}</span></a>
                <a :href="privacyPolicyLink" target="_blank"
                   class="terms-link" style="text-decoration: none"><span
                    class="link-text-style">{{ $t('LoginPage.privacyPolicy') }}</span></a>
              </el-checkbox>
            </div>

            <el-button class="login-button button-background-color" @click="login"
                       :disabled="!loginData.agree || isLoading">
              <span v-if="!isLoading">{{ $t('LoginPage.login') }}</span>
              <span v-else>
    <i class="el-icon-loading"></i>
    {{ $t('LoginPage.login') }}
  </span>
            </el-button>

<!--            <br/>
            <br/>
            <el-button class="login-button button-background-color" @click="goToApplyAccount"
                       >
              <span >申请账号</span>
            </el-button>-->

<!--            <div class="apply-account">
              <a href="https://ananda-drive.com.cn/innovative-R&D/ananda-link/" target="_blank" style="text-decoration: none"><span
                  class="link-text-style">申请账号</span></a>
            </div>-->

<!--            <div class="forgot-password">
              <a href="https://ananda-drive.com.cn/innovative-R&D/ananda-link/" target="_blank" style="text-decoration: none"><span
                  class="link-text-style">申请账号</span></a>
            </div>-->

          </el-form>
        </div>

        <!-- 找回密码内容 -->
        <div v-if="currentForm === 'resetPassword'" class="main-form">
          <!-- 找回密码标题 -->
          <div class="reset-password-header">
            <a href="#" @click="showLoginForm" class="reset-password-link">
              <el-icon class="back-arrow">
                <Back/>
              </el-icon>
              <span class="h3-text"> {{ resetPasswordHeader }}</span>
            </a>
          </div>
          <!-- 找回密码表单 -->
          <el-form ref="resetPasswordForm" :model="resetPasswordData" :rules="resetPasswordFormRules"
                   label-position="top">
            <!-- 账号输入框 -->
            <el-form-item ref="emailInput" :label=" $t('LoginPage.username')" prop="email">
              <el-input class="other-input" v-model="resetPasswordData.email"
                        :placeholder="$t('LoginPage.enterUsername')">
                <template #prepend>
                  <el-icon class="larger-icon">
                    <User/>
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>

            <!-- 验证码输入框和按钮 -->
            <el-form-item :label=" $t('LoginPage.getVerificationCode')" prop="verificationCode">
              <div class="input-with-button">
                <el-input class="custom-input" v-model="resetPasswordData.verificationCode"
                          :placeholder="$t('LoginPage.enterVerificationCode')"
                          :style="{ width: verificationCodeInputWidth }">
                  <template #prepend>
                    <el-icon class="larger-icon">
                      <Message/>
                    </el-icon>
                  </template>
                </el-input>

                <el-button
                    class="custom-button custom-reset-button"
                    :disabled="countdown > 0"
                    :style="{ backgroundColor: countdown > 0 ? '#f5f5f5' : '', color: countdown > 0 ? '#b8b8b8' : '' }"
                    @click="getVerificationCode"
                >
                  <span v-html="countdownMessage"></span>
                </el-button>

              </div>
              <!-- 添加这个 div 来显示验证码发送成功的信息 -->
              <div v-if="verificationCodeSent" class="verification-sent-message">
                {{ $t('LoginPage.verificationCodeSent') }}
              </div>
            </el-form-item>

            <!-- 密码输入框 -->
            <el-form-item :label=" $t('LoginPage.password')" prop="newPassword">
              <el-input class="other-input" v-model="resetPasswordData.newPassword" :show-password="true"
                        :placeholder="$t('LoginPage.enterNewPassword')" type="password">
                <template #prepend>
                  <el-icon class="larger-icon">
                    <Lock/>
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item :label=" $t('settingPage.confirmNewPassword')" prop="confirmPassword">
              <el-input class="other-input" v-model="resetPasswordData.confirmPassword" :show-password="true"
                        :placeholder="$t('settingPage.enterConfirmPassword')" type="password">
                <template #prepend>
                  <el-icon class="larger-icon">
                    <Lock/>
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>


            <!-- 重置密码按钮 -->
            <el-button class="login-button button-background-color" @click="resetPassword">
              {{ $t('LoginPage.resetPassword') }}
            </el-button>
          </el-form>
        </div>

      </div>
    </div>

    <el-dialog v-model="warningDialogVisible" :title="$t('ProductDiagnosis.warning')" width="700" align-center center>

      <template #header="{  titleId, titleClass }">
        <div class="my-header">
          <h1 :id="titleId" :class="titleClass" style="font-size: 30px;">
            <el-icon class="icon-style">
              <WarningFilled/>
            </el-icon>&nbsp;Safety Notice
          </h1>
        </div>
      </template>

      <p style="font-size: 20px;">
        {{ $t('LoginPage.passwordPolicy') }}
      </p>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="button-background-color" @click="handleConfirm">
            {{ $t('PluginBox.confirmButton') }}
          </el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import {ArrowDown, Back, Lock, Message, User, WarningFilled} from "@element-plus/icons-vue";
import {getUserPermissions, loginUser, resetPasswordToForget, sendMail} from "@/api/api";
import GlobalIcon from "@/components/GlobalIcon.vue";

export default {
  components: {WarningFilled, ArrowDown, Message, Back, User, Lock, GlobalIcon},
  data() {
    return {
      loginData: {
        username: "",
        password: "",
        agree: false,
        softwareVersion: '',
      },
      resetPasswordHeader: this.$t('LoginPage.recoverPassword'),
      isLoading: false,  // 新增的加载状态

      supportedLanguages: {
        '简体中文': 'zh',
        'English': 'en',
        'Italiano': 'it',
        'Francais': 'fr',
        'Deutsch': 'de',
        'Dutch': 'nl',
        '日本語': 'ja',
        'Svenska': 'sv',
        'Magyar': 'hu',
        'Polski': 'pl',
        'Español': 'es',      // 添加西班牙语
        'Português': 'pt'     // 添加葡萄牙语
      },
      selectedLanguage: this.$store.getters.currentLanguageFriendlyName,
      hover: null,
      // 初始时显示登录表单
      currentForm: 'login',
      resetPasswordData: {
        userId: '', // 用户的ID
        email: '',
        verificationCode: '',
        newPassword: '',
        confirmPassword: '', // 添加确认密码字段
      },
      countdown: 0, // 倒计时时间
      fillStr: '',
      warningDialogVisible: false,
      verificationCodeSent: false,
      loginFormRules: {
        username: [
          {required: true, message: this.$t('LoginPage.enterUsername'), trigger: 'blur'},
        ],
        password: [
          {required: true, message: this.$t('LoginPage.enterPassword'), trigger: 'blur'},
        ],
      },
    };
  },
  watch: {
    '$i18n.locale'() {
      this.resetPasswordHeader = this.$t('LoginPage.recoverPassword');
      if (this.$i18n.locale === 'en') {
        this.fillStr = '&nbsp;'.repeat(15);  // 更优雅的方式填充空格
      } else {
        this.fillStr = '';
      }
      this.loginFormRules = {
        username: [
          {required: true, message: this.$t('LoginPage.enterUsername'), trigger: 'blur'},
        ],
        password: [
          {required: true, message: this.$t('LoginPage.enterPassword'), trigger: 'blur'},
        ],
      };
    }
  },
  mounted() {
    // 设置 validateOnRuleChange 为 false 以防止在加载时触发校验
    const lang = localStorage.getItem('lang');
    if (lang && this.supportedLanguages[lang]) {
      this.selectedLanguage = lang;
      this.changeLanguage(this.selectedLanguage);
    } else {
      // 设置一个默认语言，如果localStorage中没有语言
      this.selectedLanguage = 'English';
      this.changeLanguage(this.selectedLanguage);
    }
    this.$refs.loginForm.validateOnRuleChange = false;

  },
  computed: {
    termsOfServiceLink() {
      return this.getLinkForLanguage('termsOfService');
    },
    legalNoticeLink() {
      return this.getLinkForLanguage('legalNotice');
    },
    privacyPolicyLink() {
      return this.getLinkForLanguage('privacyPolicy');
    },
    resetPasswordFormRules() {
      return {
        email: [
          {required: true, message: this.$t('LoginPage.enterUsername'), trigger: 'blur'},
        ],
        verificationCode: [
          {required: true, message: this.$t('LoginPage.enterVerificationCode'), trigger: 'blur'},
        ],
        newPassword: [
          {required: true, message: this.$t('LoginPage.enterNewPassword'), trigger: 'blur'},
        ],
        confirmPassword: [
          {required: true, message: this.$t('settingPage.enterConfirmPassword'), trigger: 'blur'},
          {validator: this.validateConfirmPassword, trigger: 'blur'}
        ]
      };
    },
    countdownMessage() {
      if (this.countdown > 0) {
        return `${this.$t('LoginPage.wait')} &nbsp; ${this.countdown} &nbsp;s ${this.fillStr}`;
      } else {
        return this.$t('LoginPage.getVerificationCode');
      }
    },
    verificationCodeInputWidth() {
      // 根据当前语言的文本长度或者其他条件计算宽度
      const baseWidth = 312; // 默认基础宽度

      // 根据具体逻辑计算宽度，例如：
      if (this.selectedLanguage === 'English') {
        return '246px'; // 假设每个字符大概需要8px
      } else if (this.selectedLanguage === '简体中文') {
        return `${baseWidth}px`;
      } else {
        return `${baseWidth}px`; // 默认返回一个值
      }
    }
  },
  methods: {
    goToApplyAccount() {
        window.open("https://ananda-drive.com.cn/innovative-R&D/ananda-link/","_blank");
    },

    getLinkForLanguage(docType) {
      const links = {
        zh: {
          termsOfService: 'https://link-public-cos.ananda-drive.com/f63a83b0-06b2-11ef-8f06-49ae7b2fadcf-1714455117675.pdf',
          legalNotice: 'https://link-public-cos.ananda-drive.com/f63b1ff0-06b2-11ef-9eac-b120705c4c0c-1714455117679.pdf',
          privacyPolicy: 'https://link-public-cos.ananda-drive.com/f63b9520-06b2-11ef-8f06-49ae7b2fadcf-1714455117682.pdf'
        },
        en: {
          termsOfService: 'https://link-public-cos.ananda-drive.com/en/f638fd10-06b2-11ef-8f06-49ae7b2fadcf-1714455117665.pdf',
          legalNotice: 'https://link-public-cos.ananda-drive.com/en/f6397241-06b2-11ef-9eac-b120705c4c0c-1714455117668.pdf',
          privacyPolicy: 'https://link-public-cos.ananda-drive.com/en/f6397240-06b2-11ef-9eac-b120705c4c0c-1714455117668.pdf'
        }
      };
      return (links[this.$i18n.locale] && links[this.$i18n.locale][docType]) || links['en'][docType]
    },
    handleBlur(prop) {
      // 仅当输入框有值时才触发校验
      if (this.loginData[prop]) {
        this.$refs.loginForm.validateField(prop);
      }
    },
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.resetPasswordData.newPassword) {
        callback(new Error(this.$t('settingPage.passwordMismatch')));
      } else {
        callback();
      }
    },
    handleConfirm() {
      this.warningDialogVisible = false;
      this.resetPasswordData.verificationCode = '';
      this.currentForm = 'resetPassword';
      this.resetPasswordHeader = this.$t('LoginPage.resetPassword');
      // 将登录页面的账号（loginData.username）自动填入忘记密码表单的邮箱输入框
      this.resetPasswordData.email = this.loginData.username;
      // 等待 DOM 更新完成后自动聚焦到邮箱输入框
      this.$nextTick(() => {
        this.$refs.emailInput.$el.querySelector('input').focus();
      });
    },
    showResetPasswordForm() {
      // 当用户点击“忘记密码？”时切换到找回密码表单
      this.currentForm = 'resetPassword';
      this.resetPasswordData.email = this.loginData.username;
      this.resetPasswordHeader = this.$t('LoginPage.recoverPassword');
    },

    async resetPassword() {
      await this.$refs.resetPasswordForm.validateField('verificationCode', async (valid) => {
        if (!valid) {
          return; // 如果验证不通过，则聚焦到输入框，并停止执行
        }

        try {
          const response = await resetPasswordToForget({
            email: this.resetPasswordData.email,
            code: this.resetPasswordData.verificationCode,
            password: this.resetPasswordData.newPassword
          });
          if (response.code === 0) {
            this.$message.success(this.$t('LoginPage.passwordResetSuccess'));
            this.currentForm = 'login';
          } else {
            this.$message.error(response.message || this.$t('LoginPage.passwordResetFailed'));
          }
        } catch (error) {
          console.error(this.$t('LoginPage.passwordResetRequestFailed'));
        }
      });
    },

    async getVerificationCode() {
      if (this.countdown > 0) return; // 如果在倒计时期间，禁止重复点击
      this.startCountdown(); // 点击发送后开始倒计时
      try {
        const response = await sendMail({email: this.resetPasswordData.email});
        if (response.code === 0) {
          this.verificationCodeSent = true; // 显示提示信息
        } else {
          console.error(response.message || this.$t('LoginPage.verificationCodeSendFailed'));
        }
      } catch (error) {
        console.error(this.$t('LoginPage.verificationCodeRequestFailed'));
      }
    },

    startCountdown() {
      this.countdown = 59; // 设置倒计时为59秒
      const interval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(interval); // 倒计时结束清除定时器
        }
      }, 1000);
    },

    showLoginForm() {
      this.currentForm = 'login';
    },
    async login() {
      if (!this.loginData.agree) {
        this.$message.error(this.$t('LoginPage.readTerms'));
        return;
      }
      try {
        await this.$refs.loginForm.validate();

        this.isLoading = true; // 开始加载

        const data = await loginUser(this.loginData.username, this.loginData.password);
        if (data.code === 0) {
          // 登录成功，保存令牌到localStorage
          localStorage.setItem('token', data.token);
          localStorage.setItem('user_email', data.user.email);
          localStorage.setItem('user_role', JSON.stringify(data.user.role));
          localStorage.setItem('openeds', JSON.stringify([]));

          localStorage.setItem('organizationId', data.user.organization_id);
          localStorage.setItem('organizationName', data.user.organization);
          localStorage.setItem('userId', data.user.id)

          // 获取用户权限并保存到localStorage
          await this.loadUserPermissions(data.user.id);

          // 读取并重定向到之前尝试访问的地址
          const redirectPath = localStorage.getItem('redirectAfterLogin') || {name: 'ProductUpgrade'};
          localStorage.removeItem('redirectAfterLogin'); // 登录后清除保存的地址
          this.$router.push(redirectPath);
        } else if (data.code === -200) {
          this.warningDialogVisible = true;
        } else {
          this.$message.error(this.$t('LoginPage.loginFailed'));
        }
      } catch (error) {
        this.$message.error(this.$t('LoginPage.loginRequestFailed'));
      } finally {
        this.isLoading = false; // 结束加载
      }
    },
    async loadUserPermissions(userId) {
      const cacheKey = `userPermissions_${userId}`;
      const subCacheKey = `subPermissions_${userId}`;
      try {
        const rawPermissions = await getUserPermissions();

        localStorage.setItem(cacheKey, JSON.stringify(rawPermissions.menus));
        localStorage.setItem(subCacheKey, JSON.stringify(rawPermissions.permissions));

      } catch (error) {
        console.error('Error fetching user permissions:', error);
      }
    },
    changeLanguage(friendlyName) {
      // 在这里触发切换语言的逻辑，你可以使用i18n插件或者自定义切换语言的方法
      this.selectedLanguage = friendlyName;
      const language = this.supportedLanguages[friendlyName];
      this.$store.dispatch('changeLanguage', {language, friendlyName});

    },
  },
};
</script>

<style scoped>
.verification-sent-message {
  color: rgb(0, 154, 68);
  font-size: 12px;
  margin-top: 5px;
}

.img-global {
  font-size: 22px;
  color: white;
  margin-right: 10px;
}

.icon-style {
  font-size: 30px;
  color: rgb(230, 162, 60);
}

.my-header {
  display: flex;
  justify-content: center;
}

.terms-link {
  margin-left: 10px;
}

.larger-icon {
  font-size: 16px;
  /* 或者您想要的任何大小 */
  color: black;
}

:deep( .custom-input .el-input-group__prepend ) {
  background-color: rgb(255, 255, 255);
  /* 设置背景颜色为白色 */
  border: none;
  /* 去除边框 */
}

:deep(.custom-input .el-input__inner) {
  border-left: none;
  /* 去除与预置插槽相邻的边框线 */
}

:deep(.other-input .el-input-group__prepend ) {
  background-color: rgb(255, 255, 255);
  /* 设置背景颜色为白色 */
  border: none;
}

:deep(.other-input .el-input__inner ) {
  border-left: none;
  /* 去除与预置插槽相邻的边框线 */
}

.other-input {
  height: 40px;
  /* 设置新的高度 */
  line-height: 40px;
  /* 调整行高以垂直居中文本 */
}


.input-with-button {
  display: flex;
  align-items: center;
  /* 确保元素在交叉轴上居中对齐 */
}

.input-with-button .el-input,
.input-with-button .el-button {
  flex-shrink: 0;
  /* 防止输入框和按钮在flex容器内收缩 */
}

.input-with-button .el-input {
  margin-right: 10px;
  /* 在输入框和按钮之间添加一些间隙 */
}

:deep(.custom-input .el-input__inner ) {
  height: 40px;
  /* 设置新的高度 */
  line-height: 40px;
  /* 调整行高以垂直居中文本 */
  width: 130px;
}

.input-with-button .custom-button {
  height: 40px;
  /* 设置按钮的新高度 */
  line-height: 40px;
  /* 使按钮内的文本垂直居中 */
}

.reset-password-header {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap; /* 添加这一行 */
}

.reset-password-link {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: black;
  /* 设置链接文本颜色 */
}

.back-arrow {
  cursor: pointer;
  font-size: 32px;
  /* 调整图标的大小 */
  font-weight: bold;
  /* 加粗图标 */
  margin-right: 10px;
  /* 设置图标和文字之间的间距 */
}

.el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

.custom-dropdown {
  position: absolute;
  top: 20px;
  /* 距离顶部的距离 */
  right: 30px;
  /* 距离右侧的距离 */
  z-index: 100;
  /* 确保下拉列表显示在其他元素之上 */
}

:deep( .el-form-item__label ) {
  margin-bottom: 0;
  /* 减少label下方的外边距 */
  padding-bottom: 0;
  /* 减少label的内边距 */
}

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% + 48px);
  background-image: linear-gradient(270.06deg,
  rgba(36, 81, 26, 0.5) 0.04%,
    /* 添加透明度到渐变颜色 */ rgba(5, 26, 0, 0.5) 101.39% /* 添加透明度到渐变颜色 */
  ),
  url("https://link-public-1310393141.cos.ap-shanghai.myqcloud.com/images/forest_bike.png");
  background-size: cover;
  /* 背景图片覆盖整个元素 */
  background-position: center;
  /* 背景图片居中 */
  background-repeat: no-repeat;
  /* 不重复背景图片 */

  position: relative;
  overflow-x: auto; /* 当内容溢出时显示水平滚动条 */
}

.login-container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  min-width: 1200px; /* 设置一个最小宽度以保证内容不被压缩 */
  width: 100%;
}

.left-pane {
  margin-left: 120px;
  padding-left: 20px;
}

.left-text {
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-family: "PingFang SC", Arial, sans-serif;
  /* 字体家族，首选PingFang SC，其次Arial，最后是sans-serif */
  width: 360px;
  /* 元素宽度为360像素 */
  height: 40px;
  /* 元素高度为40像素 */
  margin-top: 20px;
  white-space: nowrap;
}

#login-left {
  width: 700px;
  /* 元素宽度为360像素 */
  height: 63px;
  /* 元素高度为40像素 */
}

.right-pane {
  background-color: #ffffff;
  /* 白色背景 */
  padding: 64px 48px;

  width: 35%;
  max-width: 426px;
  display: flex;
  justify-content: center;
  right: 120px;
  z-index: 1;
  border-radius: 4px;
  margin-right: 120px;
  margin-left: auto;
  /* 推到右侧 */
}

.main-form {
  width: 416px;
}

.main-form {
  /* 确保容器足够宽，可以是固定宽度或者100% */
  width: 100%;
}

.main-form .el-form-item .el-input {
  /* 让 el-input 组件宽度为100% */
  width: 100%;
}

.h3-text {
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  /* 设置字体系列 */
  width: 96px;
  height: 32px;
  margin-bottom: 50px;
  margin-top: 0;
  white-space: nowrap; /* 添加这一行 */
}

.forgot-password {
  text-align: right;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  /* 设置字体系列 */
  margin-top: 30px;
  /* 向上移动 */
  margin-right: -8px;
  /* 向右移动 */
  margin-bottom: 15px;
}

.apply-account {
  text-align: center;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  /* 设置字体系列 */
  margin-top: 30px;
  /* 向上移动 */
  margin-right: -8px;
  /* 向右移动 */
  margin-bottom: 15px;
}

.privacy-agreement {
  text-align: left;
  margin-bottom: 10px;
}

.privacy-agreement span,
.privacy-agreement a {
  white-space: normal;
  line-height: 1.5; /* 调整行高，增加行间距 */
}

.login-button {
  width: 416px;
  height: 40px;
  padding: 6px 15px;
  margin: 1px 0 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  /* 设置字体系列 */
}

/* 禁用状态下的登录按钮样式 */
.el-button.login-button:disabled {
  background-color: rgb(245, 245, 245) !important;
  /* 禁用状态的背景颜色 */
  color: rgb(184, 184, 184) !important;
}
</style>
